<template>
  <div>
    <!-- manager table -->
    <div v-if="getRole === roleType.MANAGER">
      <overview-agency-list></overview-agency-list>
      <overview-customer-list></overview-customer-list>
    </div>

    <!-- agency table -->
    <div v-else-if="getRole === roleType.AGENCY">
      <overview-agency-page></overview-agency-page>
    </div>

    <!-- shop leader table -->
    <div v-else-if="isLeader === true">
      <overview-shop-leader-list></overview-shop-leader-list>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import OverviewCustomerList from "../Dashboard/Overview/OverviewCustomerList"
import OverviewAgencyList from "../Dashboard/Overview/OverviewAgencyList"
import OverviewAgencyPage from "../Dashboard/Overview/OverviewAgencyPage"
import OverviewShopLeaderList from "../Dashboard/Overview/OverviewShopLeaderList"
import { Table, TableColumn } from "element-ui"
import { roleType } from "src/util/constants"
import formMixin from "@/mixins/formMixin"
Vue.use(Table)
Vue.use(TableColumn)

export default {
  mixins: [formMixin],
  components: {
    OverviewCustomerList,
    OverviewAgencyList,
    OverviewAgencyPage,
    OverviewShopLeaderList,
  },
  data() {
    return {
      roleType: roleType,
      user: "groupLeader",
      isDashboard: "false",
    }
  },
  computed: {
    getRole() {
      return this.$store.state.auth.user?.role
    },
  },
  watch: {
    $route: {
      async handler(to, from) {
        if (
          (this.$store.state.auth.user.role === this.roleType.SHOP ||
            this.$store.state.auth.user.role === this.roleType.FREETRIAL) &&
          this.isLeader === false
        ) {
          if (to.name === "Overview") {
            this.$router.push("/dashboard")
          }
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}
</style>
