<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">
        {{ $t("screens.dashboard.buttons.updateGroupStore") }}
      </h5>
    </div>
    <div class="card-body row">
      <div class="col-md-8 ml-3">
        <div class="row align-items-center">
          <div class="col-md-2">
            <span>{{ $t("screens.dashboard.texts.groupSelect") }}</span>
          </div>
          <div class="col-md-10">
            <el-select
              v-model="groupSelection"
              :placeholder="$t('screens.dashboard.texts.pleaseChoose')"
              filterable
              :no-match-text="$t('screens.overviewAgency.label.noData')"
              clearable
            >
              <el-option
                v-for="item in shopGroup"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>

      <div class="col-md-8 mt-4 ml-3">
        <div class="row align-items-center">
          <div class="col-md-2">
            <span>{{ $t("screens.dashboard.texts.listStore") }}</span>
          </div>
          <div class="col-md-5" style="display: inline-block">
            <fg-input
              class="input-sm mb-0"
              :placeholder="$t('screens.overviewManager.label.search')"
              v-model="searchQuery"
              addon-right-icon="nc-icon nc-zoom-split"
            >
            </fg-input>
          </div>
        </div>
      </div>
      <div class="col-sm-12 mt-4">
        <div class="col-sm-12">
          <el-table
            ref="multipleTable"
            row-key="id"
            class="table-striped"
            :data="pagedData"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            :empty-text="$t('screens.overviewAgency.label.noData')"
          >
            <el-table-column
              :reserve-selection="true"
              type="selection"
              width="55"
            >
            </el-table-column>
            <el-table-column :sortable="true" min-width="70" label="ID">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.id }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
              :align="column.align"
              :sortable="column.sortable"
            >
            </el-table-column>
            <el-table-column
              class-name="action-buttons td-actions"
              align="right"
            >
              <template slot-scope="props">
                <p-button
                  :disabled="props.row.google_status === 0"
                  type="info"
                  size="sm"
                  icon
                  :title="$t('screens.buttons.dashboard')"
                  @click="handleLike(props.row)"
                >
                  <i class="fa fa-user"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="card-footer row">
      <div class="col-sm-6 pagination-info">
        <p class="category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
        </p>
        <p-button
          type="primary"
          round
          @click="viewReport"
          :disabled="multipleSelection.length === 0"
        >
          {{ $t("screens.overviewShop.buttons.viewReport") }}
        </p-button>
      </div>
      <div class="col-sm-6">
        <pagination
          class="pull-right"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="
            groupSelection
              ? paginationByGroup.total
              : paginationByLeaderId.total
          "
          :change-page="changePage(pagination.currentPage)"
        >
        </pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue"
import { Table, TableColumn, Select, Option, Checkbox } from "element-ui"
import { Pagination } from "src/components/UIComponents"
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
export default {
  components: {
    Pagination,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      groupSelection: null,
      multipleSelection: [],
      shopGroup: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["name", "email"],
      tableParents: [],
      tableColumns: [
        {
          prop: "name",
          label: this.$t("screens.overviewShop.label.customerName"),
          minWidth: 160,
          sortable: true,
        },
        {
          prop: "email",
          label: this.$t("screens.overviewShop.label.email"),
          minWidth: 150,
          sortable: true,
        },
      ],
      selectAll: false,
      valuePage: 1,
      firstPage: 1,
    }
  },
  computed: {
    pagedData() {
      return this.tableParents
    },

    to() {
      return this.groupSelection
        ? this.paginationByGroup.to
        : this.paginationByLeaderId.to
    },

    from() {
      return this.groupSelection
        ? this.paginationByGroup.from
        : this.paginationByLeaderId.from
    },

    total() {
      return this.groupSelection
        ? this.paginationByGroup.total
        : this.paginationByLeaderId.total
    },

    paginationByGroup() {
      return this.$store.state.groupsLeader.paginationByGroup
    },

    paginationByLeaderId() {
      return this.$store.state.groupsLeader.paginationByLeaderId
    },
  },
  async created() {
    await this.getListGroupsByLeader()
    await this.getListStoreByLeaderId(this.firstPage)
  },
  watch: {
    async groupSelection(value) {
      if (value) {
        await this.getListStoreByGroup(this.firstPage, this.searchQuery, value)
      } else {
        await this.getListStoreByLeaderId(this.firstPage, this.searchQuery)
      }
    },

    async valuePage(currentPage) {
      if (this.groupSelection) {
        await this.getListStoreByGroup(
          currentPage,
          this.searchQuery,
          this.groupSelection
        )
      } else {
        await this.getListStoreByLeaderId(currentPage, this.searchQuery)
      }
    },

    async searchQuery(value) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        if (this.groupSelection) {
          await this.getListStoreByGroup(
            this.firstPage,
            value,
            this.groupSelection
          )
        } else {
          await this.getListStoreByLeaderId(this.firstPage, value)
        }
      }, 500)
    },
  },
  methods: {
    handleLike(row) {
      this.$store.commit("setUserIds", row.id)
      this.$router.push({ path: "/dashboard" })
    },

    handleSelectionChange(val) {
      this.multipleSelection = val
    },

    viewReport() {
      if (this.multipleSelection.length === 1) {
        this.$router.push({ path: "/report" })
      }
      if (this.multipleSelection.length > 1) {
        this.$router.push({ path: "/report-multiple" })
      }
      this.$store.commit(
        "setUserIds",
        this.multipleSelection.map((selection) => selection.id)
      )
    },

    async getListGroupsByLeader() {
      await this.$store.dispatch("groupsLeader/getListGroupsByLeader")
      this.shopGroup = this.$store.state.groupsLeader.listGroupsByLeader
    },

    async getListStoreByGroup(page, query, id) {
      const group = id
      const params = {
        page,
        limit: this.pagination.perPage,
        store_key_search_by_group: query,
      }
      await this.$store.dispatch("groupsLeader/getListStoresByGroup", {
        group,
        params,
      })
      this.tableParents = this.$store.state.groupsLeader.listStoresByGroup
    },

    async getListStoreByLeaderId(page, query) {
      const params = {
        page,
        limit: this.pagination.perPage,
        store_key_search_by_group: query,
      }
      await this.$store.dispatch("groupsLeader/getListStoreByLeaderId", params)
      this.tableParents = this.$store.state.groupsLeader.listStoresByLeader
    },

    changePage(val) {
      this.valuePage = val
    },
  },
}
</script>

<style scoped>
.custom-checkbox {
  position: absolute;
  top: 18px;
  left: 25px;
  z-index: 10;
}
</style>
