<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">
        {{ $t("screens.overviewManager.customerTitle") }}
      </h5>
    </div>
    <div class="card-body row">
      <div class="row col-sm-12 d-flex">
        <div class="form-group col-sm-2 mr-auto">
          <el-select
            v-model="pagination.perPage"
            placeholder="Per page"
            @change="changeSelectPage(pagination.perPage)"
          >
            <el-option
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-group col-sm-2">
          <fg-input
            class="input-sm"
            :placeholder="$t('screens.overviewManager.label.search')"
            v-model="searchQuery"
            addon-right-icon="nc-icon nc-zoom-split"
            clearable
            @input="inputSearch"
          >
          </fg-input>
        </div>
      </div>
      <div class="col-sm-12">
        <el-table
          class="table-striped"
          :data="pagedData"
          :empty-text="$t('screens.overviewAgency.label.noData')"
        >
          <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :prop="column.prop"
            :label="column.label"
            :min-width="column.minWidth"
            :sortable="column.sortable"
          >
          </el-table-column>
          <el-table-column
            :sortable="true"
            min-width="85"
            align="center"
            :label="$t('screens.overviewManager.label.googleStatus')"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.google_status === 1" class="text-success">
                {{ $t("screens.dashboard.texts.googleConnected") }}
              </span>
              <span v-else class="text-danger">
                {{ $t("screens.dashboard.texts.googleDisconnected") }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            class-name="action-buttons td-actions"
            min-width="50"
            align="right"
          >
            <template slot-scope="props">
              <p-button
                type="info"
                size="sm"
                icon
                :title="$t('screens.buttons.dashboard')"
                @click="navigateToDashboard(props.row)"
              >
                <i class="fa fa-user"></i>
              </p-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="col-sm-6 pagination-info mt-2">
        <p class="category">
          {{ paginationContent }}
        </p>
      </div>
      <div v-if="getPagination.total" class="col-sm-6 mt-2">
        <pagination
          class="pull-right"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="pagination.total"
          :change-page="changePage(pagination.currentPage)"
        >
        </pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import { Table, TableColumn, Select, Option } from "element-ui"
import { Pagination } from "src/components/UIComponents"
import { convertMessage } from "src/util/commons"
import { messageType } from "src/util/constants"
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      resultSearchQuery: "",
      customers: [],
      params: {
        limit: 5,
        page: 1,
        customer_key_search: "",
      },
      valuePage: 1,
      selectValue: 5,
      firstPage: 1,

      propsToSearch: [
        "id",
        "company_name",
        "name",
        "person_in_charge",
        "email",
        "agency_name",
        "payment_method",
        "payment_status",
        "google_status",
      ],
      tableColumns: [
        {
          prop: "id",
          label: "ID",
          minWidth: 50,
          align: "center",
          sortable: true,
        },
        {
          prop: "company_name",
          label: this.$t("screens.overviewManager.label.companyName"),
          minWidth: 200,
          sortable: true,
        },
        {
          prop: "name",
          label: this.$t("screens.overviewManager.label.storeName"),
          minWidth: 200,
          sortable: true,
        },
        {
          prop: "person_in_charge",
          label: this.$t("screens.overviewManager.label.personInCharge"),
          minWidth: 100,
          sortable: true,
        },
        {
          prop: "email",
          label: this.$t("screens.overviewManager.label.mailAddress"),
          minWidth: 150,
          sortable: true,
        },
        {
          prop: "agency_name",
          label: this.$t("screens.overviewManager.label.agencyName"),
          minWidth: 100,
          sortable: true,
        },
        {
          prop: "payment_method",
          label: this.$t("screens.overviewManager.label.paymentMethod"),
          minWidth: 80,
          sortable: true,
        },
        {
          prop: "payment_status",
          label: this.$t("screens.overviewManager.label.paymentStatus"),
          minWidth: 80,
          sortable: true,
        },
      ],
    }
  },
  computed: {
    pagedData() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.pagination.total = this.getPagination.total
      return this.customers
    },
    paginationContent() {
      const { from, to, total } = this.getPagination
      return total ? `Showing ${from} to ${to} of ${total} entries` : null
    },
    getPagination() {
      return this.$store.state.customers.pagination
    },
  },
  async created() {
    this.$store.commit("setShowLoading", true)
    await this.getCustomers(this.firstPage, this.selectValue, this.searchQuery)
    this.$store.commit("setShowLoading", false)
  },
  watch: {
    valuePage(currentPage) {
      if (currentPage !== this.firstPage) {
        this.getCustomers(currentPage, this.selectValue, this.searchQuery)
      } else {
        this.getCustomers(this.firstPage, this.selectValue, this.searchQuery)
      }
    },
  },
  methods: {
    navigateToDashboard(row) {
      this.$store.commit("setUserIds", row.id)
      this.$router.push({ path: "/dashboard" })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    async getCustomers(page, option, keySearch) {
      try {
        this.messages = null
        this.params = {
          limit: option,
          page: page,
          customer_key_search: keySearch,
        }

        await this.$store.dispatch("customers/getModels", this.params)
        this.customers = this.$store.state.customers.list
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
      }
    },
    changePage(val) {
      this.valuePage = val
    },
    changeSelectPage(val) {
      this.selectValue = val
      this.getCustomers(this.valuePage, this.selectValue, this.searchQuery)
    },
    inputSearch() {
      this.getCustomers(this.valuePage, this.selectValue, this.searchQuery)
    },
  },
}
</script>

<style></style>
