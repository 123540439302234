<template>
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <div class="card">
        <div class="card-header">
          <h5 slot="header" class="card-title">
            {{ $t("screens.overviewManager.agencyTitle") }}
          </h5>
        </div>

        <div class="card-body row">
          <div class="row col-sm-12 d-flex">
            <div class="form-group col-sm-2 mr-auto">
              <el-select
                v-model="pagination.perPage"
                placeholder="Per page"
                @change="changeSelectPage(pagination.perPage)"
              >
                <el-option
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="form-group col-sm-2">
              <fg-input
                class="input-sm"
                :placeholder="$t('screens.overviewManager.label.search')"
                v-model="searchQuery"
                addon-right-icon="nc-icon nc-zoom-split"
                @input="inputSearch"
              >
              </fg-input>
            </div>
          </div>
          <div class="col-sm-12">
            <el-table
              ref="multipleTable"
              :data="pagedData"
              row-key="id"
              type="expand"
              :empty-text="$t('screens.overviewAgency.label.noData')"
              @current-change="handleCurrentChange"
              @row-click="navigateToAgencies(agencyId)"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :prop="column.prop"
                :label="column.label"
                :min-width="column.minWidth"
                :sortable="column.sortable"
              >
              </el-table-column>
              <el-table-column
                :sort-method="sortID"
                :sortable="true"
                min-width="70"
                :label="$t('screens.overviewManager.label.logo')"
              >
                <template slot-scope="scope">
                  <img
                    style="width: 40px; height: 40px"
                    :src="scope.row.agency_logo"
                    alt=""
                  />
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info mt-2">
            <p class="category">
              {{ paginationContent }}
            </p>
          </div>
          <div v-if="getPagination.total" class="col-sm-6 mt-2">
            <pagination
              class="pull-right"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
              :change-page="changePage(pagination.currentPage)"
            >
            </pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import { convertMessage } from "src/util/commons"
import { messageType } from "src/util/constants"
import { Table, TableColumn, Select, Option } from "element-ui"
import { Pagination } from "src/components/UIComponents"
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      list: [],
      agencyId: "",
      params: {
        limit: 5,
        page: 1,
        agency_key_search: "",
      },
      valuePage: 1,
      selectValue: 5,
      firstPage: 1,

      propsToSearch: [
        "id",
        "code",
        "company_name",
        "person_in_charge",
        "email",
        "application_name",
      ],
      tableColumns: [
        {
          prop: "id",
          label: "ID",
          minWidth: 50,
          sortable: true,
        },
        {
          prop: "code",
          label: this.$t("screens.overviewManager.label.agencyCode"),
          minWidth: 80,
          sortable: true,
        },
        {
          prop: "company_name",
          label: this.$t("screens.overviewManager.label.companyName"),
          minWidth: 100,
          sortable: true,
        },
        {
          prop: "person_in_charge",
          label: this.$t("screens.overviewManager.label.personInCharge"),
          minWidth: 100,
          sortable: true,
        },
        {
          prop: "email",
          label: this.$t("screens.overviewManager.label.mailAddress"),
          minWidth: 200,
          sortable: true,
        },
        {
          prop: "application_name",
          label: this.$t("screens.overviewManager.label.applicationName"),
          minWidth: 150,
          sortable: true,
        },
      ],
    }
  },
  computed: {
    pagedData() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.pagination.total = this.getPagination?.total
      return this.list
    },
    paginationContent() {
      const { from, to, total } = this.getPagination
      return total ? `Showing ${from} to ${to} of ${total} entries` : null
    },
    getPagination() {
      return this.$store.state.agencies.pagination
    },
  },
  async created() {
    this.$store.commit("setShowLoading", true)
    await this.getAgencies(this.firstPage, this.selectValue, this.searchQuery)
    this.$store.commit("setShowLoading", false)
  },
  watch: {
    valuePage(currentPage) {
      if (currentPage !== this.firstPage) {
        this.getAgencies(currentPage, this.selectValue, this.searchQuery)
      } else {
        this.getAgencies(this.firstPage, this.selectValue, this.searchQuery)
      }
    },
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    async getAgencies(page, option, keySearch) {
      try {
        this.messages = null
        this.params = {
          limit: option,
          page: page,
          agency_key_search: keySearch,
        }

        await this.$store.dispatch("agencies/getModels", this.params)
        this.list = this.$store.state.agencies.list
      } catch (err) {
        this.messages = {
          data: this.$t("actions.errors.500"),
          type: messageType.ERROR,
        }
        if (err.response?.data?.errors) {
          this.messages = {
            data: convertMessage(err.response.data.errors),
            type: messageType.ERROR,
          }
        }
      }
    },
    sortID(a, b) {
      return a.id < b.id ? -1 : 1
    },
    handleCurrentChange(val) {
      this.agencyId = val.id
    },
    navigateToAgencies(id) {
      this.$router.push({ path: `/overview/agencies/${id}` })
    },
    changePage(val) {
      this.valuePage = val
    },
    changeSelectPage(val) {
      this.selectValue = val
      this.getAgencies(this.valuePage, this.selectValue, this.searchQuery)
    },
    inputSearch() {
      this.getAgencies(this.valuePage, this.selectValue, this.searchQuery)
    },
  },
}
</script>

<style scoped>
.el-table >>> .el-table__row {
  cursor: pointer;
}
</style>
